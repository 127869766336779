import { Timestamp } from 'firebase/firestore';

export type landingPageTypes = 'LANDING' | 'DETAILS' | 'SUMMARY';

export enum landingPageEnum {
  LANDING = 'LANDING',
  DETAILS = 'DETAILS',
  SUMMARY = 'SUMMARY',
}

export enum drivewaySizesEnum {
  ONE_CAR = 'ONE_CAR',
  TWO_CAR = 'TWO_CAR',
  THREE_CAR = 'THREE_CAR',
  CUSTOM_CAR = 'CUSTOM_CAR',
}

//Needs to be in USD
// There is a dependancy, when checking out, calling stripe, it takes the USD and converts it into pennies.
export enum drivewaySizesPricesEnum {
  ONE_CAR = 600,
  TWO_CAR = 650,
  THREE_CAR = 700,
  CUSTOM_CAR = 850,
}

export enum WBLPriceEnum {
  SNOW_SHOWVELING_PRICE = 250,
}
export type drivewayDetailsTypes = {
  address: string;
  city: string;
  state: string;
  zipcode: string;
  size: drivewaySizesEnum;
  seasonPrice: drivewaySizesPricesEnum;
  notes: string;
  wbl: {
    price: number;
    selected: boolean;
  };
  discounts: {
    referral: boolean;
    neighbor: boolean;
    earlyBird: boolean;
  };
  overrides: {
    customDrivewayPrice: {
      isActive: boolean;
      value: number;
    };
    customShovelingPrice: {
      isActive: boolean;
      value: number;
    };
  };
};

export type dialogsTypes = {
  newDriveway: boolean;
  estimateSentToEmail: boolean;
};

export enum pricingStategyEums {
  ONE_MONTH = 0,
  TWO_MONTH = 1,
  THREE_MONTH = 2,
}

export type summrayTypes = {
  pricingStategy: pricingStategyEums;
  balanceDue: number; // This is the total of the season price + if wbl is selected  *  for each drivewayDetailsArray  details. / the selected pricingStategy value
};

// These are the configs coming back from the firebase
export type landingByteCrateTypes = {
  activeLandingView: {
    snowAlwaysLandsOnTopView: boolean;
    defaultView: boolean;
  };
  snowAlwaysLandsOnTop: {
    landingPageHook: string;
    landingPageMessage: string;
    landingPageMessageUpdated: Timestamp;
    snowEmergencyDeclared: boolean;
    snowEventDate: Timestamp;
    cardSnowEvent: {
      active: boolean;
      url: string | null;
    };
    cardPriorityPush: {
      active: boolean;
      url: string | null;
    };
  };
  activeSnowEventId: string | null;
};

export type DotComState = {
  isLoading: boolean;
  landingPage: landingPageTypes;
  tenantId: string | null;
  clientId: string | null;
  sfMananaSessionId: string | null;
  onboardingSnowClientDetails: onboardingSnowClientDetailsTypes;
  drivewayDetailsArray: drivewayDetailsTypes[];
  dialogs: dialogsTypes;
  summary: summrayTypes; // When the driveway array is updated. We should update the summary too.
  snowPromoDialog: checkTenantPromoResponse;
  snowPromoDialogIsOpen: boolean;
  buttonStates: buttonsStateTypes;
  indicators: {
    isLocked: boolean;
    easterEgg: number;
    easterEggIndex: number;
    savedSession: boolean;
  };
  landingByteCrate: landingByteCrateTypes;
};

export type buttonsStateTypes = {
  showEmailDetails: boolean;
};
export type checkTenantPromoResponse = {
  isActive: boolean;
  dialog: {
    dialogImage: string;
  };
  details: {
    amount: number;
  };
  vendorDetails: {
    name: string;
    address: string;
    logoUrl: string;
  };
};
export type onboardingSnowClientDetailsTypes = {
  fName: string | null;
  lName: string | null;
  phone: string | null;
  email: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  zipcode: string | null;
};
