import { Link as RouterLink } from 'react-router-dom';
import { Box, BoxProps } from '@mui/material';
import appLogo from '../assets/perales_lawn_logo.svg';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const logo = (
    <Box sx={{ width: 60, height: 60, ...sx }}>
      <img src={appLogo} alt="Perales Lawn Service Logo" />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
