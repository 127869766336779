import { createSlice } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';
import { ClientViewState } from '../../@types/clientView';
import { dispatch } from '../store';

const DEFAULT_ZERO_NUMBER_VALUE = 0;

const initialState: ClientViewState = {
  isLoading: false,
  landingPage: null,
  tenantId: null,
  clientId: null,
  estimateId: null,
  token: null,
  clientProfileVertificationSuccess: false,
  vertifyButtonStateActive: false,
  checkoutButtonStateActive: false,
  clientPhoneNumber: null,
  clientDetails: null,
  serviceCompletedDetails: null,
  serviceCompletedSummary: null,
  pageView: 'profile details',
  recentServicesView: null,
  completedServiceView: null,
  profileDetails: null,
  viewEstiamteLanding: null,
  profilePageIndicators: {
    showUpcomingServicesSection: false,
  },
  //todo: move this to a global redux state
  commentDialog: {
    isOpen: false,
    comment: null,
  },
  noteDetailDialog: {
    isOpen: false,
    details: null,
  },
  estiamteStatusDialog: {
    isOpen: false,
    comment: null,
  },
  profileSummary: {
    balanceDueInPennies: DEFAULT_ZERO_NUMBER_VALUE,
    taxesDueInPennies: DEFAULT_ZERO_NUMBER_VALUE,
    totalDueInPennies: DEFAULT_ZERO_NUMBER_VALUE,
    nextServiceScheduledFor: null,
  },
};

const slice = createSlice({
  name: 'clientView',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    setClientViewDetailsFromParams(state, action) {
      state.tenantId = action.payload.tenantId;
      state.clientId = action.payload.clientId;
      state.estimateId = action.payload.estimateId;
      state.token = action.payload.tokenken;
      state.landingPage = action.payload.viewType;
    },
    setClientPhoneNumber(state, action) {
      state.clientPhoneNumber = action.payload;
    },
    clearClientPhoneNumber(state) {
      state.clientPhoneNumber = null;
    },
    setClientDetailsFromDB(state, action) {
      const clientViewVertifyResponse = action.payload;
      //TODO: we should do some logic here to check if the guest should still see the button/ update the clientServiceDate response section to return the  state of the button.

      state.pageView = clientViewVertifyResponse.pageView;
      state.recentServicesView = clientViewVertifyResponse.recentServicesView
        ? clientViewVertifyResponse.recentServicesView
        : null;
      state.completedServiceView = clientViewVertifyResponse.completedServiceView
        ? clientViewVertifyResponse.completedServiceView
        : null;
      state.profileDetails = clientViewVertifyResponse.profileDetails;

      state.clientDetails = clientViewVertifyResponse.profileDetails;

      state.serviceCompletedDetails = clientViewVertifyResponse.recentServicesView.serviceDates
        ? clientViewVertifyResponse.recentServicesView.serviceDates
        : null;
      state.serviceCompletedSummary = clientViewVertifyResponse.recentServicesView.checkoutSummary
        ? clientViewVertifyResponse.recentServicesView.checkoutSummary
        : null;

      // Setting the summary details from the response:

      state.profileSummary.balanceDueInPennies =
        clientViewVertifyResponse.checkoutSummary.balanceDueInPennies;
      state.profileSummary.taxesDueInPennies =
        clientViewVertifyResponse.checkoutSummary.taxesDueInPennies;
      state.profileSummary.totalDueInPennies =
        clientViewVertifyResponse.checkoutSummary.totalDueInPennies;

      // Setting the Next Service date string:
      state.profileSummary.nextServiceScheduledFor =
        clientViewVertifyResponse.checkoutSummary.nextServiceScheduledFor;
    },

    setEstimateLandingDetailsFromDB(state, action) {
      const clientViewVertifyResponse = action.payload;

      const nonParseddateRequested =
        clientViewVertifyResponse.viewEstimateLandingPage.dateRequested;
      const nonParsedTimeDateScheduled =
        clientViewVertifyResponse.viewEstimateLandingPage.dateScheduled;
      const dateRequestedTimeObj = new Timestamp(
        Number(nonParseddateRequested._seconds),
        Number(nonParseddateRequested._nanoseconds)
      );

      const dateRequested = dateRequestedTimeObj.toDate().toDateString();

      state.pageView = clientViewVertifyResponse.pageView;

      state.profileDetails = clientViewVertifyResponse.profileDetails;
      state.clientDetails = clientViewVertifyResponse.profileDetails;
      state.viewEstiamteLanding = clientViewVertifyResponse.viewEstimateLandingPage;
      state.viewEstiamteLanding.dateRequested = dateRequested;

      if (nonParsedTimeDateScheduled != null) {
        const dateScheduledTimeObj = new Timestamp(
          Number(nonParsedTimeDateScheduled._seconds),
          Number(nonParsedTimeDateScheduled._nanoseconds)
        );

        const dateScheduled = dateScheduledTimeObj.toDate().toDateString();

        state.viewEstiamteLanding.dateScheduled = dateScheduled;
      }
    },

    setClientProfileVertificationSuccessTrue(state) {
      state.clientProfileVertificationSuccess = true;
    },
    setVertifyButtonState(state) {
      state.vertifyButtonStateActive = true;
    },
    setCheckoutButtonToActive(state) {
      state.checkoutButtonStateActive = true;
    },
    setClientViewPage(state, action) {
      const page = action.payload;
      state.pageView = page;
    },

    openEstimateDialog(state) {
      state.commentDialog.isOpen = true;
    },

    closeEstimateDialog(state) {
      state.commentDialog.isOpen = false;
    },
    updateEstimateComments(state, action) {
      const newComments = action.payload;
      state.viewEstiamteLanding.comments = newComments;
    },

    openEstimateStatusDialog(state) {
      state.estiamteStatusDialog.isOpen = true;
    },

    closeEstimateStatusDialog(state) {
      state.estiamteStatusDialog.isOpen = false;
    },
    updatingEstimateLineItemStatus(state, action) {
      const updatedRequestList = action.payload;
      state.viewEstiamteLanding.requestList = updatedRequestList;
    },
    showProfileUpcomingServicesSection(state) {
      state.profilePageIndicators.showUpcomingServicesSection = true;
    },

    updateNoteDialogState(state, action) {
      state.noteDetailDialog.details = action.payload;
    },
    openNoteDialog(state) {
      state.noteDetailDialog.isOpen = true;
    },
    closeNoteDialog(state) {
      state.noteDetailDialog.isOpen = false;
      state.noteDetailDialog.details = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  endLoading,
  setClientViewDetailsFromParams,
  setClientPhoneNumber,
  setClientDetailsFromDB,
  setEstimateLandingDetailsFromDB,
  setVertifyButtonState,
  clearClientPhoneNumber,
  setClientProfileVertificationSuccessTrue,
  setCheckoutButtonToActive,
  setClientViewPage,
  openEstimateDialog,
  closeEstimateDialog,
  updateEstimateComments,
  openEstimateStatusDialog,
  closeEstimateStatusDialog,
  updatingEstimateLineItemStatus,
  showProfileUpcomingServicesSection,
  updateNoteDialogState,
  openNoteDialog,
  closeNoteDialog,
} = slice.actions;

export function closeNotesDialogState() {
  return async () => {
    dispatch(closeNoteDialog());
  };
}

export function openNotesDialogState() {
  return async () => {
    dispatch(openNoteDialog());
  };
}

export function updateNoteDialogDetailsState(payload: any) {
  return async () => {
    dispatch(updateNoteDialogState(payload));
  };
}
export function showProfilePageUpcomingServicesSection() {
  return async () => {
    dispatch(showProfileUpcomingServicesSection());
  };
}

export function handleUpdatingEstimateLineItemStatus(payload: any) {
  return async () => {
    dispatch(updatingEstimateLineItemStatus(payload));
  };
}

export function handleUpdatingNewComment(payload: any) {
  return async () => {
    dispatch(updateEstimateComments(payload));
  };
}

export function handleEstiamteStatusDialogState(state: boolean) {
  if (state) {
    return async () => {
      dispatch(openEstimateStatusDialog());
    };
  } else {
    return async () => {
      dispatch(closeEstimateStatusDialog());
    };
  }
}

export function handleCommentDialogState(state: boolean) {
  if (state) {
    return async () => {
      dispatch(openEstimateDialog());
    };
  } else {
    return async () => {
      dispatch(closeEstimateDialog());
    };
  }
}

export function setPageView(payload: any) {
  return async () => {
    dispatch(setClientViewPage(payload));
  };
}

export function setClientProfileVertificationSuccessToTrue() {
  return async () => {
    dispatch(setClientProfileVertificationSuccessTrue());
  };
}

export function setCheckoutButtonToActice() {
  return async () => {
    dispatch(setCheckoutButtonToActive());
  };
}

export function updateVertifyButtonState() {
  return async () => {
    dispatch(setVertifyButtonState());
  };
}
export function setClientViewInitialDetails(payload: {
  tenantId: string;
  clientId: string;
  estimateId: string | undefined | null;
  token: string | undefined | null;
  viewType: 'Client' | 'Estimate';
}) {
  return async () => {
    dispatch(setClientViewDetailsFromParams(payload));
  };
}

export function setClientViewLoadingTrue() {
  return async () => {
    dispatch(startLoading());
  };
}

export function setClientViewLoadingFalse() {
  return async () => {
    dispatch(endLoading());
  };
}

export function setClientViewPhoneNumber(payload: string) {
  return async () => {
    dispatch(setClientPhoneNumber(payload));
  };
}

export function clearClientViewPhoneNumber() {
  return async () => {
    dispatch(clearClientPhoneNumber());
  };
}

export function setClientViewDetailsFromDB(payload: any) {
  console.log(
    'can we check to see if the response can set the error state too from the data base? So if there is an error we can show the guest the error: Kinda of a mapper: ',
    payload
  );
  return async () => {
    dispatch(setClientDetailsFromDB(payload));
  };
}

export function setEstimateLandingDetails(payload: any) {
  return async () => {
    dispatch(setEstimateLandingDetailsFromDB(payload));
  };
}
