import { Link as RouterLink } from 'react-router-dom';
import { Box, BoxProps } from '@mui/material';
import snowLogo from '../assets/snowlogo.svg';
import { useSelector } from 'src/redux/store';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function LogoSnow({ disabledLink = true, sx }: Props) {
  const { tenantId } = useSelector((state: any) => state.dotcom);
  const redirectLink = `/snowBlowing/${tenantId}`;
  const logo = (
    <Box sx={{ width: 60, height: 60, ...sx }}>
      <img src={snowLogo} alt="Perales Lawn Service Logo" />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to={redirectLink}>{logo}</RouterLink>;
}
