import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import LoadingLogoScreen from 'src/components/LoadingLogoScreen ';
import { useParams } from 'react-router-dom';
import {
  setDotComLoadingFalse,
  setDotComLoadingTrue,
  setLandingByteCrateConfigToReduxState,
  setTenantPromoDetails,
  setTenantSnowBlowingInitialDetails,
  updateDotComState,
} from 'src/redux/slices/dotcom';
import {
  checkSFMananaDocument,
  checkTenantPromo,
  getLandingByteCrateConfig,
} from 'src/contexts/FirebaseContext';

import useQuery from 'src/utils/useQuery';
import { DotComState, landingPageEnum } from 'src/@types/dotCom';
import useAuth from 'src/hooks/useAuth';
import { Timestamp } from 'firebase/firestore';
// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function SnowGuard({ children }: GuestGuardProps) {
  const { isLoading } = useSelector((state: any) => state.dotcom);
  const { signInNewGuest } = useAuth();
  const { tenantId } = useParams();
  const dispatch = useDispatch();
  let query = useQuery();

  // This is the very top of the tree.
  //
  useEffect(() => {
    if (tenantId !== undefined) {
      dispatch(setDotComLoadingTrue());
      signInNewGuest()
        .then(async () => {
          await callSetTenantSnowBlowingInitialDetails();
          await checkQueryParamas();
        })
        .finally(() => {
          dispatch(setDotComLoadingFalse());
        });
    } else {
      console.log('THROW ERROR HERE');
    }
  }, []);

  const callSetTenantSnowBlowingInitialDetails = async () => {
    const LANDING_BYTE_CRATE_DOCUMENT = 'landingByteCrate';

    const landingByteCrateConfigValues = await getLandingByteCrateConfig(
      tenantId,
      LANDING_BYTE_CRATE_DOCUMENT
    );
    dispatch(setLandingByteCrateConfigToReduxState(landingByteCrateConfigValues));
    dispatch(setTenantSnowBlowingInitialDetails(tenantId));
  };
  const checkQueryParamas = async () => {
    await checkForPromoQueryAndValidate();
    await checkForSFLMananaQueryAndValidate();
  };

  const checkForSFLMananaQueryAndValidate = async () => {
    const SFL_QUERY_PARAM = 'sfmanana';
    const slfQueryValue = query.get(SFL_QUERY_PARAM);
    if (slfQueryValue) {
      // Set the state config sag
      const sessionDetailsResponse = await checkSFMananaDocument(tenantId, slfQueryValue);
      if (sessionDetailsResponse.isActive) {
        // Details has the redux state
        const details = sessionDetailsResponse.documentData;

        console.log(
          'this is the sfLManana, it should return the updated param, if we visit this after paying for it? If its true, we should set the state here, then look at the landing page or set the disable buttons to true '
        );

        console.log('details: ', details);
        // CHEKC IF THE SEASON HAS BEEN ACCEPTED?
        // Contact CTA replaces the Pay CTA
        // On Submit we should update this sfManana collection to
        // We can create h\the client on payment success? SPKIE

        const DotComStateValues: DotComState = {
          isLoading: false,
          tenantId: tenantId ? tenantId : null,
          sfMananaSessionId: slfQueryValue,
          indicators: {
            isLocked: details?.configs?.isLocked ? details.configs.isLocked : false,
            savedSession: true,
            easterEgg: 0,
            easterEggIndex: 0,
          },
          clientId: null,
          landingPage: landingPageEnum.SUMMARY,
          onboardingSnowClientDetails: details.onboardingSnowClientDetails,
          drivewayDetailsArray: details.drivewayDetailsArray,
          dialogs: {
            newDriveway: false,
            estimateSentToEmail: false,
          },
          summary: details.summary,
          snowPromoDialog: {
            isActive: false,
            dialog: {
              dialogImage: '',
            },
            details: {
              amount: 0,
            },
            vendorDetails: {
              name: 'Metta Coffee',
              address: '800 Main St. Anoka, MN 55303',
              logoUrl: 'string',
            },
          },
          snowPromoDialogIsOpen: false,

          buttonStates: {
            showEmailDetails: false,
          },
          landingByteCrate: {
            activeLandingView: {
              snowAlwaysLandsOnTopView: false,
              defaultView: true,
            },
            snowAlwaysLandsOnTop: {
              landingPageHook: '',
              landingPageMessage: '',
              landingPageMessageUpdated: Timestamp.now(),
              snowEmergencyDeclared: false,
              snowEventDate: Timestamp.now(),
              cardPriorityPush: {
                active: false,
                url: null,
              },
              cardSnowEvent: {
                active: false,
                url: null,
              },
            },
            activeSnowEventId: null,
          },
        };

        dispatch(updateDotComState(DotComStateValues));

        // ADD ICONS
        // POST PURCHASE?
      }
    }
  };

  const checkForPromoQueryAndValidate = async () => {
    const PROMO_QUERY_PARAM = 'promo';
    const promoValue = query.get(PROMO_QUERY_PARAM);
    if (promoValue) {
      // SINCE ITS VALID SET THE WHOLE SCREEN AS LOADING
      // FETCH THE DETAILS FROM FIREBASE
      // MAP THEM TO THE REDUX STATRE
      const promoResponse = await checkTenantPromo(tenantId, promoValue);
      const OPEN_DIALOG = true;
      if (promoResponse.isActive) {
        dispatch(setTenantPromoDetails(promoResponse, OPEN_DIALOG));
        // Vendors: Name, LogoUrl, Website, Instagram?
        // promoTitle
        // promoSubTitle
        // promoDescrition
        // Promo Action:
      }
    }
  };

  if (isLoading) {
    return <LoadingLogoScreen />;
  }
  return <>{children}</>;
}
