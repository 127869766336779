import { createContext, ReactNode, useEffect, useReducer, useState } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInAnonymously,
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  DocumentData,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  Timestamp,
  arrayUnion,
  serverTimestamp,
} from 'firebase/firestore';

import { getFunctions } from 'firebase/functions';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

// @types
import { ActionMap, AuthState, AuthUser, FirebaseContextType } from '../@types/auth';
// @types
import { UserManager } from '../@types/user';
//
import { FIREBASE_API } from '../config';
import {
  setClientsTodoTodayToReduxState,
  setClientMessagesToReduxState,
  setClientListToReduxState,
  setEstiamteListToReduxState,
  clientHaveBeenFetched,
  setClientProfileServiceAddressesDetailsToReduxState,
  setProfileInvoiceDetailsToReduxState,
  setEstiamtePageClientDetails,
  setTodaysNumbersToState,
  setEstiamtePageDetailsToState,
  setPendingSchedulingList,
  setCrewMembersList,
  tenantCrewHasBeenFetched,
  setReduxStateWithFirebaseDetails,
  setReduxStateWithFirebaseSnowSnapshotDetails,
  setTenantRoutesToReduxState,
} from '../redux/slices/dashboard';
import { dispatch } from '../redux/store';
import { checkTenantPromoResponse, landingByteCrateTypes } from 'src/@types/dotCom';
import {
  invoiceDetailsTypes,
  newSnowEventTypes,
  SnowEventDetailsProps,
  SnowEventPageTypes,
  updateClientProfileTypes,
} from 'src/@types/dashboard';
import {
  landingByteCrateFetchedSuccesfully,
  tenantCrewMembersFetchedSuccesfully,
} from 'src/redux/slices/byteCrate';

const ADMIN_EMAILS = ['demo@minimals.cc'];

const TENANTS_COLLECTION = 'tenants';

const firebaseApp = initializeApp(FIREBASE_API);

const storage = getStorage(firebaseApp);

export const AUTH = getAuth(firebaseApp);

export const DB = getFirestore(firebaseApp);

export const functions = getFunctions(firebaseApp);

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

enum Types {
  Initial = 'INITIALISE',
}
type FormValuesProps = UserManager;

type FirebaseAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
};

type FirebaseActions = ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    console.log('reducer', user);
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext<FirebaseContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [profile, setProfile] = useState<DocumentData | undefined>();

  useEffect(
    () =>
      onAuthStateChanged(AUTH, async (user) => {
        if (user && !user.isAnonymous) {
          const userRef = doc(DB, 'users', user.uid);
          const docSnap = await getDoc(userRef);
          if (docSnap.exists()) {
            setProfile(docSnap.data());
          }

          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: true, user },
          });
          console.log('NEW USER', user);
        } else {
          console.log('NO USER');
          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: false, user: null },
          });
        }
      }),
    [dispatch]
  );

  const login = (email: string, password: string) =>
    signInWithEmailAndPassword(AUTH, email, password);

  const signInNewGuest = () =>
    signInAnonymously(AUTH)
      .then(() => {})
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('todo: : ');
        console.log('errorCode: ', errorCode);
        console.log('errorMessage: ', errorMessage);
        // ...
      });

  const register = (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    businessName: string
  ) =>
    createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
      const userRef = doc(collection(DB, 'users'), res.user?.uid);

      const newItem = await addDoc(collection(DB, 'tenants'), {
        createdAt: Timestamp.now(),
        active: true,
        businessName: businessName,
      });
      await setDoc(userRef, {
        uid: res.user?.uid,
        email,
        displayName: `${firstName} ${lastName}`,
        tenantID: newItem.id,
        role: 'user',
        businessName: businessName,
        premiumStatus: false,
        trailDaysLeft: 7,
      });
      return newItem.id;
    });

  const logout = () => signOut(AUTH);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: state?.user?.uid,
          email: state?.user?.email,
          photoURL: state?.user?.photoURL || profile?.photoURL,
          displayName: state?.user?.displayName || profile?.displayName,
          role: ADMIN_EMAILS.includes(state?.user?.email) ? 'admin' : 'user',
          phoneNumber: state?.user?.phoneNumber || profile?.phoneNumber || '',
          country: profile?.country || '',
          address: profile?.address || '',
          state: profile?.state || '',
          city: profile?.city || '',
          zipCode: profile?.zipCode || '',
          about: profile?.about || '',
          isPublic: profile?.isPublic || false,
          tenantId: profile?.tenantID || '',
          businessName: profile?.businessName,
          premiumStatus: profile?.premiumStatus,
          trailDaysLeft: profile?.trailDaysLeft,
        },
        login,
        register,
        logout,
        signInNewGuest,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

async function testing(data: FormValuesProps) {
  try {
    const docRef = await addDoc(collection(DB, TENANTS_COLLECTION), {
      email: data.email,
      phone: data.phoneNumber,
    });

    console.log('Document written with ID: ', docRef.id);
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

/*
This function will look at the tenant Snapshot collection and will pull down the lastest array with clientId being the key and the data its values. 
To be able to use the data, we needed to manuplicate it here and put it into an array of objcts. 
*/
async function getTenantClientList(tenantId: string) {
  const TENANT_CLIENT_LIST_PATH = `tenants/${tenantId}/snapshots`;
  console.log('calling client list multiple times. Need to refactor the inital config logic.  ');

  try {
    const userRef = doc(DB, TENANT_CLIENT_LIST_PATH, 'clientList');
    const docSnap = await getDoc(userRef);
    const data: any = docSnap.data();
    console.log('we need to update the client in the back end');
    console.log('this is the data we are getting back from the list: ', data);
    const dataOrginized: { UID: any; name: any; steps: any; avtiv: any; totalBalanceDue: any }[] =
      [];
    const newDatarray: any[] = [];

    for (let [key, value] of Object.entries(data)) {
      const tempMap = new Map<String, any>();
      const newreWorked: any = value;
      tempMap.set('uid', key);

      for (let [key1, value1] of Object.entries(newreWorked)) {
        tempMap.set(key1, value1);
      }
      newDatarray.push(tempMap);
    }

    newDatarray.forEach((element) => {
      const name = element.get('clientName');
      const steps = element.get('completed');
      const avtiv = element.get('active');
      const address = element.get('serviceAddress');
      const totalBalanceDue = element.get('totalBalanceDue');
      const UID = element.get('uid');
      const payloadd = {
        UID,
        name,
        steps,
        avtiv,
        totalBalanceDue,
        address,
      };
      dataOrginized.push(payloadd);
    });
    dispatch(setClientListToReduxState(dataOrginized));
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function getTenantClientMessages(tenantId: string) {
  const FLAG_HAS_NOT_RESPONDED = 'responded';
  const q = query(collection(DB, tenantId), where(FLAG_HAS_NOT_RESPONDED, '==', false));

  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const documentData = doc.data();

      const date = new Date(documentData.timestamp.toDate());
      const payload = {
        uid: doc.id,
        name: documentData.name,
        mobile: documentData.mobile,
        address: documentData.address,
        message: documentData.message,
        email: documentData.email,
        responded: documentData.responded,
        timestamp: date.toDateString(),
        expanded: false,
      };

      dispatch(setClientMessagesToReduxState(payload));
    });
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function getTenantTodoScheduleForToday(tenantId: string, dayOfTheWeek: string) {
  const TENANT_MESSAGE_PATH = `tenants/${tenantId}/snapshots`;
  try {
    const userRef = doc(DB, TENANT_MESSAGE_PATH, dayOfTheWeek);
    const docSnap = await getDoc(userRef);
    const data: any = docSnap.data();

    const dataOrginized: {
      UID: any;
      name: any;
      contactPhone: any;
      serviceType: any;
      serviceAddress: any;
      servicePrice: any;
      serviceDescription: any;
      serviceDay: any;
      totalBalanceDue: any;
      active: any;
      completed: any;
    }[] = [];

    var projectedRevenue = 0;
    var totalRevenue = 0;

    const newDatarray = [];
    if (data !== undefined) {
      for (let [key, value] of Object.entries(data)) {
        const tempMap = new Map<String, any>();
        const newreWorked: any = value;
        tempMap.set('uid', key);

        for (let [key1, value1] of Object.entries(newreWorked)) {
          tempMap.set(key1, value1);
        }
        console.log('tempMap: ', tempMap);
        newDatarray.push(tempMap);
      }

      newDatarray.forEach((element) => {
        const UID = element.get('uid');
        const name = element.get('clientName');
        const contactPhone = element.get('contactPhone');
        const serviceType = element.get('serviceType');
        const serviceAddress = element.get('serviceAddress');
        const servicePrice = element.get('servicePrice');
        const totalBalanceDue = element.get('totalBalanceDue');
        const serviceDescription = element.get('serviceDescription');
        const serviceDay = element.get('serviceDay');
        const active = element.get('active');
        const completed = element.get('completed');

        const payload = {
          UID,
          name,
          contactPhone,
          serviceType,
          servicePrice,
          serviceAddress,
          serviceDescription,
          serviceDay,
          totalBalanceDue,
          active,
          completed,
        };

        projectedRevenue = projectedRevenue + parseInt(payload.servicePrice, 10);
        if (payload.completed) {
          totalRevenue = totalRevenue + parseInt(payload.servicePrice, 10);
        }

        if (!payload.completed) {
          dataOrginized.push(payload);
        }
      });

      const numbers = {
        projectedRevenue,
        totalRevenue,
      };

      dispatch(setTodaysNumbersToState(numbers));
      dispatch(setClientsTodoTodayToReduxState(dataOrginized));
    }
    dispatch(clientHaveBeenFetched());
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function getTenantsClientProfileServiceAddressesDetails(
  tenantId: string,
  arrayOfDocuments: any
) {
  const TENANT_CLIENT_DETAILS_PATH = `tenants/${tenantId}/clientServiceAddresses/`;

  try {
    arrayOfDocuments.forEach(async (firestorePathID: any) => {
      const userRef = doc(collection(DB, TENANT_CLIENT_DETAILS_PATH), firestorePathID);
      const docSnap = await getDoc(userRef);
      const docData = docSnap.data();

      if (docSnap.exists() && docData) {
        const paylaod = {
          clientUid: docData.clientUid,
          serviceAddressUid: docSnap.id,
          serviceAddress: docData.serviceAddress,
          serviceCity: docData.serviceCity,
          serviceState: docData.serviceState,
          serviceZip: docData.serviceZip,
          serviceCompleted: docData.serviceCompleted,
          serviceDay: docData.serviceDay,
          servicePrice: docData.servicePrice,
          serviceType: docData.serviceType,
        };
        dispatch(setClientProfileServiceAddressesDetailsToReduxState(paylaod));
      } else {
        console.log('No such document!');
      }
    });
  } catch (e) {
    console.log('error', e);
  }
}

async function getTenantsClientInvoiceDetails(tenantId: string, invoiceUid: string) {
  const TENANT_CLIENTS_PROFILE_INVOICE_PATH = `tenants/${tenantId}/clientInvoices`;

  try {
    const invoiceRef = doc(collection(DB, TENANT_CLIENTS_PROFILE_INVOICE_PATH), invoiceUid);
    const invoiceSnap = await getDoc(invoiceRef);
    const invoiceData = invoiceSnap.data();

    if (invoiceSnap.exists() && invoiceData) {
      const payload = {
        ...invoiceData,
        invoiceUid: invoiceSnap.id,
      };

      dispatch(setProfileInvoiceDetailsToReduxState(payload));
    }
  } catch (e) {
    console.log('error', e);
  }
}

async function setCompletedService(
  TIMEFIELD: string,
  TENANT_MESSAGE_PATH: string,
  serviceCompetedDate?: any,
  serviceCompetedType?: any,
  servicePrice?: any,
  serviceDescription?: any,
  serviceDay?: any,
  serviceNotes?: any,
  serviceRef?: any
) {
  try {
    const cityRef = doc(DB, TENANT_MESSAGE_PATH, 'completedServices');

    setDoc(
      cityRef,
      {
        [TIMEFIELD]: {
          paid: false,
          serviceType: serviceCompetedType,
          serviceDate: serviceCompetedDate,
          servicePrice: servicePrice,
          serviceNotes: serviceNotes,
          serviceDescription: serviceDescription,
          serviceDay: serviceDay,
          serviceRef: serviceRef,
        },
      },
      { merge: true }
    );
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function updateClientCompletedFlag(
  clientUid: any,
  TENANT_MESSAGE_PATH: any,
  serviceDay: any,
  clientName: any,
  contactPhone: any,
  serviceAddress: any,
  servicePrice: any,
  serviceType: any,
  serviceDescription: any
) {
  try {
    const washingtonRef = doc(DB, TENANT_MESSAGE_PATH, serviceDay);

    await updateDoc(washingtonRef, {
      [clientUid]: {
        active: true,
        clientName: clientName,
        contactPhone: contactPhone,
        completed: true,
        serviceAddress: serviceAddress,
        servicePrice: servicePrice,
        serviceType: serviceType,
        serviceDay: serviceDay,
        serviceDescription: serviceDescription,
      },
    });
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

/*
 * This function writes to the Firestore client section. Which then triggers a function to update the new clients service day, and their snapshot
 *
 **/
//ENHANCEMENT, we should probably not make such a critical function call depend on a param with "any" type.
async function postNewClient(
  tenantNewClientPath: string,
  payload: any,
  invoiceDetails: invoiceDetailsTypes | null,
  serviceAddressArray: any[] | null //TODO: Replace this and the backwards compat to use this flow
) {
  try {
    const userRef = doc(collection(DB, tenantNewClientPath));
    const pricingStategyValuePresent = payload.summary != undefined;
    var buildingServiceAddressArrayForBackwardsCompat: {
      serviceAddress: any;
      serviceCity: any;
      serviceState: any;
      serviceZip: any;
      serviceCountry: any;
      serviceId: any;
      serviceDay: any;
      servicePrice: any;
      serviceType: any;
      serviceDescription: any;
      wbl: { price: any; selected: any };
      active: boolean;
    }[] = [];

    if (serviceAddressArray != null) {
      serviceAddressArray.map((addressDetails) => {
        const isSnowFlow = addressDetails.wbl != undefined;

        buildingServiceAddressArrayForBackwardsCompat.push({
          serviceAddress: addressDetails.address,
          serviceCity: addressDetails.city,
          serviceState: addressDetails.state,
          serviceZip: addressDetails.zipcode,
          serviceCountry: 'USA',
          serviceId: addressDetails.size,
          serviceDay: 'snow',
          servicePrice: addressDetails.seasonPrice,
          serviceType: addressDetails.size,
          serviceDescription: addressDetails.size,
          wbl: {
            price: isSnowFlow ? addressDetails.wbl.price : 0,
            selected: isSnowFlow ? addressDetails.wbl.selected : false,
          },
          active: true,
        });
      });
    } else {
      const isSnowFlowFromPayload = payload.wbl != undefined;
      buildingServiceAddressArrayForBackwardsCompat = [
        {
          serviceAddress: payload.billingAddress,
          serviceCity: payload.billingCity,
          serviceState: payload.billingState,
          serviceZip: payload.billingZip,
          serviceCountry: payload.billingCountry,
          serviceId: payload.serviceId,
          serviceDay: payload.serviceDate,
          servicePrice: payload.servicePrice,
          serviceType: payload.serviceType,
          serviceDescription: payload.serviceDescription,
          wbl: {
            price: isSnowFlowFromPayload ? payload.wbl.price : 0,
            selected: isSnowFlowFromPayload ? payload.wbl.selected : false,
          },
          active: true,
        },
      ];
    }
    console.log(
      'buildingServiceAddressArrayForBackwardsCompat: ',
      buildingServiceAddressArrayForBackwardsCompat
    );
    // // We're checking the enumCase here
    // // 0 = ONE_MONTH
    // // 1 = TWO_MONTH
    // // 2 = THREE_MONTH
    // 3 = FOUR_MONTH
    // var pricingStategyValueRaw = 'ONE_MONTH';

    // if (Number(pricingStategyValue) === 1) {
    //   pricingStategyValueRaw = 'TWO_MONTH';
    // }
    // if (Number(pricingStategyValue) === 2) {
    //   pricingStategyValueRaw = 'THREE_MONTH';
    // }

    await setDoc(userRef, {
      clientName: `${payload.firstName} ${payload.lastName}`,
      firstName: payload.firstName,
      lastName: payload.lastName,
      contactPhone: payload.contactPhone,
      contactEmail: payload.contactEmail,
      billingAddress: payload.billingAddress,
      billingCity: payload.billingCity,
      billingState: payload.billingState,
      billingZip: payload.billingZip,
      billingCountry: payload.billingCountry,
      active: true,
      snowRemovalDetails: {
        pricingStategy: pricingStategyValuePresent ? payload.summary.pricingStategy : null,
        balancePaid: pricingStategyValuePresent ? payload.summary.balancePaid : 0,
        balanceDue: pricingStategyValuePresent ? payload.summary.balanceDue : 0,
      },
      clientInvoiceDetails: {
        openBalance: invoiceDetails ? invoiceDetails.openBalance : 0,
        openInvoice: invoiceDetails ? invoiceDetails.openInvoice : 0,
        totalCompetedServices: invoiceDetails ? invoiceDetails.totalCompetedServices : 0,
        nextDueDate: invoiceDetails ? invoiceDetails.nextDueDate : null,
        preAuthorizedAmount: invoiceDetails ? invoiceDetails.preAuthorizedAmount : 0,
      },
      serviceAddresses: buildingServiceAddressArrayForBackwardsCompat,
    });
    return userRef.id;
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function getClientProfile(tenantId: string, clientUid: string) {
  const TENANT_CLIENT_LIST_PATH = `tenants/${tenantId}/clients`;
  const TENANT_CLIENT_COMPLETED_SERVICE_LIST = `tenants/${tenantId}/clients/${clientUid}/snapshots`;
  const TENANT_CLIENT_ESTIMATE_LIST = `tenants/${tenantId}/clients/${clientUid}/estimates`;
  const TENANT_CLIENT_PROFILE_SUMMARY = `tenants/${tenantId}/clients/${clientUid}/profile`;
  const SUMMARY_DOCUMENT = 'summary';

  try {
    const userRef = doc(DB, TENANT_CLIENT_LIST_PATH, clientUid);
    const docSnap = await getDoc(userRef);
    var data: any = docSnap.data();
    data = { ...data, clientUid };

    const clientCompletedServiceList = doc(
      DB,
      TENANT_CLIENT_COMPLETED_SERVICE_LIST,
      'completedServices'
    );
    const clinetCompletedServiceListRef = await getDoc(clientCompletedServiceList);
    const clinetCompletedServiceListData: any = clinetCompletedServiceListRef.data();

    // Get all of the documents in the Client Estimate collection and upate the data object if
    // it's not empty
    const clientEstimateList = await getDocs(collection(DB, TENANT_CLIENT_ESTIMATE_LIST));

    if (!clientEstimateList.empty) {
      var clientEstimates: {
        clientEstimateUuidCollectionReference: string;
        tenantEstimateUuidCollectionRefrence: any;
        status: any;
        name: string;
      }[] = [];
      clientEstimateList.forEach((estiamte) => {
        // doc.data() is never undefined for query doc snapshots
        const parsedData = estiamte.data();
        console.log(estiamte.id, ' => ', estiamte.data());
        clientEstimates.push({
          clientEstimateUuidCollectionReference: estiamte.id,
          tenantEstimateUuidCollectionRefrence: parsedData.tenantEstimateUuidCollectionRefrence,
          status: parsedData.status,
          name: parsedData.name,
        });
      });
      data = { ...data, clientEstimates };
    }

    const completedServiceList: any[] = [];
    const newDatarray = [];

    if (clinetCompletedServiceListData !== undefined) {
      for (let [key, value] of Object.entries(clinetCompletedServiceListData)) {
        const tempMap = new Map<String, any>();
        const newreWorked: any = value;
        tempMap.set('uid', key);

        for (let [key1, value1] of Object.entries(newreWorked)) {
          tempMap.set(key1, value1);
        }
        newDatarray.push(tempMap);
      }

      newDatarray.forEach((element) => {
        const paid = element.get('paid');
        const serviceDate = element.get('serviceDate');
        const serviceDay = element.get('serviceDay');
        const serviceDescription = element.get('serviceDescription');
        const serviceNotes = element.get('serviceNotes');
        const servicePrice = element.get('servicePrice');
        const serviceRef = element.get('serviceRef');
        const serviceType = element.get('serviceType');
        const sentToClient = element.get('sentToClient');
        const UID = element.get('uid');

        const payloadd = {
          UID,
          paid,
          serviceDate,
          serviceDay,
          serviceDescription,
          serviceNotes,
          servicePrice,
          serviceRef,
          serviceType,
          sentToClient,
        };
        completedServiceList.push(payloadd);
      });

      data = { ...data, completedServiceList };
    }

    // Getting Client Profile Summary Data
    const clientProfileSummary = doc(DB, TENANT_CLIENT_PROFILE_SUMMARY, SUMMARY_DOCUMENT);
    const clientProfileSummaryRef = await getDoc(clientProfileSummary);

    // Checking if the profile exists
    if (clientProfileSummaryRef.exists()) {
      const clientProfileSummaryData = clientProfileSummaryRef.data();
      console.log(
        'clientProfileSummaryData:  should we check this is not null or something? ',
        clientProfileSummaryData.authorizedAmountInPennies
      );
      data = {
        ...data,
        authorizedAmountInPennies: clientProfileSummaryData.authorizedAmountInPennies,
        balanceDueInPennies: clientProfileSummaryData.balanceDueInPennies,
      };
    } else {
      console.error(
        `No such document! ${TENANT_CLIENT_PROFILE_SUMMARY}/${SUMMARY_DOCUMENT}, setting default values of 0`
      );
      data = {
        ...data,
        authorizedAmountInPennies: 0,
        balanceDueInPennies: 0,
      };
    }

    return data;
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function postNewClientForEstaimte(tenantNewClientPath: string, payload: any) {
  try {
    const userRef = doc(collection(DB, tenantNewClientPath));

    await setDoc(userRef, {
      clientName: payload.name,
      firstName: payload.name,
      lastName: payload.name,
      contactPhone: payload.contactPhone,
      contactEmail: payload.contactEmail,
      billingAddress: payload.billingAddress,
      billingCity: payload.billingCity,
      billingState: payload.billingState,
      billingZip: payload.billingZip,
      billingCountry: payload.billingCountry,
      active: false,
      clientInvoiceDetails: {
        openBalance: '0.00',
        openInvoice: ' ',
        totalCompetedServices: 0,
      },
      serviceAddresses: [
        {
          serviceAddress: payload.billingAddress,
          serviceCity: payload.billingCity,
          serviceState: payload.billingState,
          serviceZip: payload.billingZip,
          serviceCountry: payload.billingCountry,
          serviceId: '',
          serviceDay: '',
          servicePrice: '',
          serviceType: '',
          serviceDescription: '',
          active: false,
        },
      ],
    });
    return userRef.id;
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}
async function postNewEstimate(
  tenantNewEstimatePath: string,
  estiamteRequestPayload: {
    name: any;
    contactEmail: any;
    contactPhone: any;
    billingAddress: any;
    billingCity: any;
    billingState: any;
    billingZip: any;
    billingCountry: string;
  },
  requestList: {
    clientUid: string | undefined;
    serviceId: string;
    serviceType: string;
    serviceDescription: string;
    serviceAddress: string;
    isRecurring: boolean;
  }[]
) {
  try {
    const userRef = doc(collection(DB, tenantNewEstimatePath));
    const currentTime = new Date();
    const statusPayload = {
      activeStep: 1,
      clientInitalView: false,
      notifications: {
        client: false,
        tenant: false,
      },
      sentToClientForReview: false,
      stage: 'CREATED',
    };

    await setDoc(userRef, {
      name: estiamteRequestPayload.name,
      contactEmail: estiamteRequestPayload.contactEmail,
      contactPhone: estiamteRequestPayload.contactPhone,
      clientUid: requestList[0].clientUid,
      status: statusPayload,
      clientViewed: false,
      sent: false,
      dateRequested: currentTime,
      dateScheduled: null,
      comments: null,
      requestList,
    });

    const payload = {
      ...estiamteRequestPayload,
      clientUid: requestList[0].clientUid,
      estimateUid: userRef.id,
      status: statusPayload,
      clientViewed: false,
      sent: false,
      dateRequested: currentTime,
      dateScheduled: null,
      comments: null,
      requestList: requestList,
    };

    dispatch(setEstiamtePageDetailsToState(payload));

    return userRef.id;
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function getEstimateDetails(tenantId: string, estimateUid: string) {
  const TENANT_ESTIMSTE_VAULT_PATH = `tenants/${tenantId}/estimates`;
  try {
    const userRef = doc(DB, TENANT_ESTIMSTE_VAULT_PATH, estimateUid);
    const docSnap = await getDoc(userRef);
    var data: any = docSnap.data();
    data = { ...data, estimateUid };
    return data;
  } catch (e) {
    console.error('Error getting document: ', estimateUid);
  }
}

async function getTenantEstimateList(tenantId: string) {
  const TENANT_ESTIMATE_LIST_PATH = `tenants/${tenantId}/snapshots`;

  try {
    const userRef = doc(DB, TENANT_ESTIMATE_LIST_PATH, 'estimateList');
    const docSnap = await getDoc(userRef);
    const data: any = docSnap.data();
    const dataOrginized: any[] = [];
    const newDatarray: any[] = [];

    for (let [key, value] of Object.entries(data)) {
      const tempMap = new Map<String, any>();
      const newreWorked: any = value;
      tempMap.set('uid', key);

      for (let [key1, value1] of Object.entries(newreWorked)) {
        tempMap.set(key1, value1);
      }
      newDatarray.push(tempMap);
    }

    newDatarray.forEach((element) => {
      const clientUid = element.get('clientUid');
      const dateRequestedRaw = element.get('dateRequested');
      const name = element.get('name');
      const sent = element.get('sent');
      const status = element.get('status');
      const uid = element.get('uid');
      const timeObj = new Timestamp(dateRequestedRaw.seconds, dateRequestedRaw.nanoseconds);
      const dateRequested = timeObj.toDate().toDateString();

      const payloadd = {
        uid,
        clientUid,
        dateRequested,
        sent,
        status,
        name,
      };
      dataOrginized.push(payloadd);
    });
    dispatch(setEstiamteListToReduxState(dataOrginized));
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function getClientDetails(path: string, id: string) {
  try {
    const invoiceRef = doc(collection(DB, path), id);
    const invoiceSnap = await getDoc(invoiceRef);
    const invoiceData = invoiceSnap.data();
    if (invoiceSnap.exists() && invoiceData) {
      const payload = {
        ...invoiceData,
        id: invoiceSnap.id,
      };
      console.log('setting this data to the redux state: ', payload);
      dispatch(setEstiamtePageClientDetails(payload));
    }
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function getTenantStripeId(path: string, id: string) {
  try {
    const docRef = doc(DB, path, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const tenantData = docSnap.data();
      return {
        stripeAccountId: tenantData.stripeAccountId,
        snowStripeTaxID: tenantData.snowStripeTaxID,
      };
    } else {
      throw new Error('NO_TENANT_INFO');
    }
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function updateTenantStipeAccountInDataBase(id: string) {
  try {
    const tenantRef = doc(DB, 'tenants', id);
    await updateDoc(tenantRef, {
      hasTenantSetUpStripe: true,
    });
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function updateEstimatePrice(estimateId: string, tenantId: string, requestListValue: any) {
  try {
    const tenantRef = doc(DB, `tenants/${tenantId}/estimates`, estimateId);
    await updateDoc(tenantRef, {
      requestList: requestListValue,
    });
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function updateEstimateServiceDate(
  estimateId: string,
  tenantId: string,
  dateScheduledValue: any
) {
  try {
    const tenantRef = doc(DB, `tenants/${tenantId}/estimates`, estimateId);
    setDoc(tenantRef, { dateScheduled: dateScheduledValue }, { merge: true });
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

export type newCommentTypes = {
  action: string;
  comment: string;
  fullName: string;
  timestamp: Timestamp;
  viewedByClient: boolean;
  viewedByTenant: boolean;
  addedBy: string;
};

async function addNewEstimateComment(
  estimateId: string,
  tenantId: string,
  newCommentPayload: newCommentTypes[]
) {
  try {
    const tenantRef = doc(DB, `tenants/${tenantId}/estimates`, estimateId);
    setDoc(tenantRef, { comments: newCommentPayload }, { merge: true });
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function updateEstimateLineItemStatus(
  estimateId: string,
  tenantId: string,
  newEstimateLineItemPayload: any[],
  updateStatusPayload: {
    shouldUpdate: boolean;
    status: any;
  },
  clientId: string
) {
  try {
    const tenantRef = doc(DB, `tenants/${tenantId}/estimates`, estimateId);
    if (updateStatusPayload.shouldUpdate) {
      console.log('update the state, since this is a major logic ');
      // status here is update wrong, we shjould be updatoimng the status.stagenot the whole section
      // TODO: CHECK HERE IS THE ESTMATE OR THJE CLIENT PROFIBLE HAS BEEN LOADED. HOW DO WE CHECK IF THE ESTIMATE HAS BEEN LOADED INTO THE CLIENT COLLECTION?
      // WE NEED TO DOCUMENT WHY THERE ARE TWO THINGS
      // WHEN WE LOAD THE CLIENT DETAILS, WE DONT
      setDoc(
        tenantRef,
        {
          status: updateStatusPayload.status,
          requestList: newEstimateLineItemPayload,
        },
        { merge: true }
      );
      updateClientEstiamteStatus(tenantId, clientId, estimateId, updateStatusPayload.status.stage);
    } else {
      console.log('update the state, since this is a major logic 2');
      setDoc(tenantRef, { requestList: newEstimateLineItemPayload }, { merge: true });
      updateClientEstiamteStatus(tenantId, clientId, estimateId, updateStatusPayload.status.stage);
    }
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function updateEstimateStatus(
  estimateId: string,
  tenantId: string,
  newEstimateStatusPayload: {
    activeStep: number;
    clientInitalView: boolean;
    notifications: { client: boolean; tenant: boolean };
    sentToClientForReview: boolean;
    stage: string;
  }
) {
  try {
    const tenantRef = doc(DB, `tenants/${tenantId}/estimates`, estimateId);
    setDoc(tenantRef, { status: newEstimateStatusPayload }, { merge: true });
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function updateClientProfileServiceAddress(
  clientId: string,
  tenantId: string,
  newServiceAddressPayload: any
) {
  try {
    const tenantClientRef = doc(DB, `tenants/${tenantId}/clients`, clientId);
    setDoc(
      tenantClientRef,
      {
        serviceAddresses: newServiceAddressPayload,
      },
      { merge: true }
    );
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function getTenantPendingSchedulingList(tenantId: string) {
  const TENANT_MESSAGE_PATH = `tenants/${tenantId}/snapshots`;
  try {
    const userRef = doc(DB, TENANT_MESSAGE_PATH, 'scheduled');
    const docSnap = await getDoc(userRef);
    const data: any = docSnap.data();

    const dataOrginized: {
      firebaseKey: any;
      UID: any;
      active: any;
      clientName: any;
      completed: any;
      contactPhone: any;
      serviceAddress: any;
      serviceDay: any;
      serviceDescription: any;
      servicePrice: any;
      serviceType: any;
    }[] = [];

    const newDatarray = [];
    if (data !== undefined) {
      for (let [key, value] of Object.entries(data)) {
        const tempMap = new Map<String, any>();
        const newreWorked: any = value;
        tempMap.set('uid', key);

        for (let [key1, value1] of Object.entries(newreWorked)) {
          tempMap.set(key1, value1);
        }
        newDatarray.push(tempMap);
      }

      newDatarray.forEach((element) => {
        const firebaseKey = element.get('uid');
        const clientName = element.get('clientName');
        const active = element.get('active');
        const contactPhone = element.get('contactPhone');
        const serviceType = element.get('serviceType');
        const serviceAddress = element.get('serviceAddress');
        const servicePrice = element.get('servicePrice');
        const serviceDescription = element.get('serviceDescription');
        const serviceDay = element.get('serviceDay');
        const completed = element.get('completed');
        const scheduleFor = element.get('scheduleFor');
        const UID = firebaseKey.split('<>')[0];

        const payload = {
          firebaseKey,
          UID,
          active,
          clientName,
          contactPhone,
          serviceType,
          servicePrice,
          serviceAddress,
          serviceDescription,
          serviceDay,
          completed,
          scheduleFor,
        };

        if (!payload.completed) {
          dataOrginized.push(payload);
        }
      });

      dispatch(setPendingSchedulingList(dataOrginized));
      return newDatarray;
    }
    //  dispatch(clientHaveBeenFetched());
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function handleUploadToTenantClientBucket(tenantId: string, clienttId: string, file: any) {
  if (!file) {
    alert('Please upload an image first!');
  }
  var urlToReturn = null;
  const fileStorageRef = `${tenantId}/${clienttId}/images/${file.name}`;
  const storageRef = ref(storage, fileStorageRef);
  await uploadBytes(storageRef, file).then(async (snapshot) => {
    urlToReturn = await getDownloadURL(snapshot.ref);
  });
  return { url: urlToReturn, storageRefrence: fileStorageRef };
}
async function addNewNoteDetailsToClientEstimate(
  tenantId: string,
  estiamteId: string,
  newRequestListArray: any
) {
  console.log('newRequestListArray: ', newRequestListArray);
  const estimateDocumentRef = doc(DB, `tenants/${tenantId}/estimates/`, estiamteId);
  // Atomically add a new region to the "requestList" array field.
  setDoc(
    estimateDocumentRef,
    {
      requestList: newRequestListArray,
    },
    { merge: true }
  );
}
async function updareNoteDetailsToClientEstimate(
  tenantId: string,
  estiamteId: string,
  newRequestListArray: any
) {
  const estimateDocumentRef = doc(DB, `tenants/${tenantId}/estimates/`, estiamteId);
  // Atomically add a new region to the "requestList" array field.
  await updateDoc(estimateDocumentRef, {
    requestList: newRequestListArray,
  });
}

async function getTenantCrew(tenantId: string) {
  const TENANT_MESSAGE_PATH = `tenants/${tenantId}/snapshots`;
  try {
    const crewListRef = doc(DB, TENANT_MESSAGE_PATH, 'crewList');
    const docSnap = await getDoc(crewListRef);
    const data: any = docSnap.data();

    const dataOrginized: {
      firebaseKey: any; // Use this to map back as the Key in the Key Pair Value during any Updates to this Document.....
      // The rest of the keys should match the fields names in the database:
      businessName: string;
      displayName: string;
      email: string;
      role: string;
      tenantID: string;
      uid: string;
      phoneNumber: string;
      federalFee: string;
      quickbooksFee: string;
      stateFee: string;
      workersComp: string;
      hourlyRate: string;
      assignedRoute: string | null;
    }[] = [];
    const newDatarray = [];
    if (data !== undefined) {
      for (let [key, value] of Object.entries(data)) {
        const tempMap = new Map<String, any>();
        const newreWorked: any = value;
        tempMap.set('uid', key);

        for (let [key1, value1] of Object.entries(newreWorked)) {
          tempMap.set(key1, value1);
        }
        newDatarray.push(tempMap);
      }

      newDatarray.forEach((element) => {
        const firebaseKey = element.get('uid');

        const businessName = element.get('businessName');
        const displayName = element.get('displayName');
        const email = element.get('email');
        const role = element.get('role');
        const tenantID = element.get('tenantID');
        const phoneNumber = element.get('phoneNumber');
        const federalFee = element.get('federalFee');
        const quickbooksFee = element.get('quickbooksFee');
        const stateFee = element.get('stateFee');
        const workersComp = element.get('workersComp');
        const hourlyRate = element.get('hourlyRate');
        const assignedRoute = element.get('assignedRoute');
        const uid = firebaseKey;

        const payload = {
          firebaseKey,
          uid,
          businessName,
          displayName,
          email,
          role,
          tenantID,
          phoneNumber,
          federalFee,
          quickbooksFee,
          stateFee,
          workersComp,
          hourlyRate,
          assignedRoute,
        };

        dataOrginized.push(payload);
      });
      // Set the updated array object into the state:
      dispatch(setCrewMembersList(dataOrginized));
      return newDatarray;
    }
    dispatch(tenantCrewHasBeenFetched());
    dispatch(tenantCrewMembersFetchedSuccesfully());
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function checkTenantPromo(
  tenantId: string | undefined,
  promo: string
): Promise<checkTenantPromoResponse> {
  const TENANT_MESSAGE_PATH = `tenants/${tenantId}/promos`;
  const docRef = doc(DB, TENANT_MESSAGE_PATH, promo);
  const docSnap = await getDoc(docRef);

  var isActiveRawValue = false;
  var amountRawValue = 25; // DEFAULT
  var dialogImage = '';

  if (docSnap.exists()) {
    console.log('Document data:', docSnap.data());
    isActiveRawValue = docSnap.data().isActive;
    amountRawValue = docSnap.data().discount;
    dialogImage = docSnap.data().dialogImage;
  } else {
    // docSnap.data() will be undefined in this case
    console.log('No such promo! ');
  }
  return {
    isActive: isActiveRawValue,
    dialog: {
      dialogImage: dialogImage,
    },
    details: {
      amount: amountRawValue,
    },
    vendorDetails: {
      name: 'Metta Coffee',
      address: '800 Main St. Anoka, MN 55303',
      logoUrl: 'string',
    },
  };
}

async function checkSFMananaDocument(
  tenantId: string | undefined,
  sessionId: string
): Promise<any> {
  const TENANT_SF_MANANA_SNOW_SESSION_PATH = `tenants/${tenantId}/sflSnowSessions`;
  const docRef = doc(DB, TENANT_SF_MANANA_SNOW_SESSION_PATH, sessionId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return {
      isActive: true,
      documentData: docSnap.data(),
    };
  } else {
    return {
      isActive: false,
      documentData: null,
    };
  }
}

async function getTenantSaveForMananaDocuments(tenantId: string) {
  const sflSnowSessionsRefs = query(collection(DB, `tenants/${tenantId}/sflSnowSessions`));

  try {
    const sfMananaQuerySnapshot = await getDocs(sflSnowSessionsRefs);
    var arrayOfSaveForManana: DocumentData[] = [];
    sfMananaQuerySnapshot.forEach((doc) => {
      const documentData = doc.data();
      const documentID = doc.id;
      // TODO: SANANTIZE DATAAAAA

      arrayOfSaveForManana.push({
        documentId: documentID,
        sessionData: documentData,
      });
      console.log('je je', documentData);
    });
    console.log('The Array: ', arrayOfSaveForManana);

    dispatch(setReduxStateWithFirebaseDetails(arrayOfSaveForManana));
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function getTenantSnowSnapshot(tenantId: string) {
  const TENANT_SNAPSHOTS_PATH = `tenants/${tenantId}/snapshots`;
  try {
    const userRef = doc(DB, TENANT_SNAPSHOTS_PATH, 'snow');
    const docSnap = await getDoc(userRef);
    const data: any = docSnap.data();

    const dataOrginized: {
      firebaseKey: any;
      UID: any;
      active: any;
      clientName: any;
      completed: any;
      contactPhone: any;
      serviceAddress: any;
      serviceDay: any;
      serviceDescription: any;
      servicePrice: any;
      serviceType: any;
    }[] = [];

    const newDatarray = [];
    if (data !== undefined) {
      for (let [key, value] of Object.entries(data)) {
        const tempMap = new Map<String, any>();
        const newreWorked: any = value;
        tempMap.set('uid', key);

        for (let [key1, value1] of Object.entries(newreWorked)) {
          tempMap.set(key1, value1);
        }
        newDatarray.push(tempMap);
      }

      newDatarray.forEach((element) => {
        const firebaseKey = element.get('uid');
        const clientName = element.get('clientName');
        const active = element.get('active');
        const contactPhone = element.get('contactPhone');
        const serviceType = element.get('serviceType');
        const serviceAddress = element.get('serviceAddress');
        const servicePrice = element.get('servicePrice');
        const serviceDescription = element.get('serviceDescription');
        const serviceDay = element.get('serviceDay');
        const completed = element.get('completed');
        const routeAssigned = element.get('routeAssigned');
        const UID = firebaseKey.split('<>')[0];

        const payload = {
          firebaseKey,
          UID,
          active,
          clientName,
          contactPhone,
          serviceType,
          servicePrice,
          serviceAddress,
          serviceDescription,
          serviceDay,
          completed,
          routeAssigned,
        };

        if (!payload.routeAssigned) {
          dataOrginized.push(payload);
        }
      });

      dispatch(setReduxStateWithFirebaseSnowSnapshotDetails(dataOrginized));
      return newDatarray;
    }
    //  dispatch(clientHaveBeenFetched());
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

/**
 *
 * updates the client profile with snow route details.
 * These details are passed into the client profile.
 *
 * @param tenantId
 * @param clienttId
 */
async function handleUpdatingClientDetailsWithRouteDetails(
  tenantId: string,
  clienttId: string,
  payload: updateClientProfileTypes
) {
  const tenantRef = doc(DB, `tenants/${tenantId}/clients`, clienttId);
  const routeObjectToSaveInThArray = {
    routeFBId: payload.routeFirebaseIdValue,
    routeName: payload.routeName,
    isAvtive: true,
  };
  const routeArrayToSave = [routeObjectToSaveInThArray];

  // This is setting the default values to the firebase client documnet. We're assuming that this is onlt being called from the snow dashboard.
  // As all of the new snow clients once the Stripe webhook fires for a succesfull payment, the clients profile gets updated with the relevant invoice due.
  // Once that is done at some point the client SFManana session and details are saved to the Snow folder SNapshot. This is where all new Snow clients are being stored to.
  // Once they go there, the're rendered in a list, where this funciton is called from, to update the new Client SNow session with a route.

  setDoc(
    tenantRef,
    {
      routes: routeArrayToSave,
      indicators: {
        hasNewClientBeenAWK: true,
      },
      snowMarkers: {
        installed: payload.snowMarkersInstalledValue,
        amount: 2,
      },
    },
    { merge: true }
  );
}

async function udpateRouteClientDetailsWholeArray(
  tenantId: string,
  routeFireBaseDocumentId: string,
  sortedClientDetailsArrayToUpdate: any[]
) {
  const TENANT_ROUTES_PATH = `tenants/${tenantId}/routes/`;
  // Add a new document with a generated id.

  const routeDocumentRef = doc(DB, TENANT_ROUTES_PATH, routeFireBaseDocumentId);
  await updateDoc(routeDocumentRef, {
    clientDetails: sortedClientDetailsArrayToUpdate,
  });
}

async function createNewRouteForTenant(tenantId: string, routeNameValue: string) {
  const TENANT_SNAPSHOTS_PATH = `tenants/${tenantId}/routes/`;

  // Add a new document with a generated id.
  const docRef = await addDoc(collection(DB, TENANT_SNAPSHOTS_PATH), {
    routeSnapshot: {
      name: routeNameValue,
    },
  });
  console.log('Document written with ID: ', docRef.id);

  // Atomically add a new region to the "regions" array field.
  // await updateDoc(washingtonRef, {
  //   regions: arrayUnion('greater_virginia'),
  // });
}

async function getTenantRoutes(tenantId: string) {
  const routeQueryRef = query(collection(DB, `tenants/${tenantId}/routes`));

  try {
    const routeQueryDocs = await getDocs(routeQueryRef);
    var arrayOfRoutes: DocumentData[] = [];
    routeQueryDocs.forEach((doc) => {
      const documentData = doc.data();
      const documentID = doc.id;
      // TODO: SANANTIZE DATAAAAA

      arrayOfRoutes.push({
        documentId: documentID,
        sessionData: documentData,
      });
    });
    dispatch(setTenantRoutesToReduxState(arrayOfRoutes));
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function ackClientFromSnapshotSnowFolder(
  clientUid: any,
  TENANT_MESSAGE_PATH: any,
  clientDetails: any[]
) {
  try {
    const washingtonRef = doc(DB, TENANT_MESSAGE_PATH, 'snow');
    console.log('serviceDayL ', clientDetails);

    await updateDoc(washingtonRef, {
      [clientUid]: {
        active: true,
        clientName: clientDetails[0].clientName,
        contactPhone: clientDetails[0].contactPhone,
        completed: false,
        serviceAddress: clientDetails[0].serviceAddress,
        servicePrice: clientDetails[0].servicePrice,
        serviceType: clientDetails[0].serviceType,
        serviceDay: clientDetails[0].serviceDay,
        serviceDescription: clientDetails[0].serviceDescription,
        routeAssigned: true,
      },
    });
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function updateRouteWithNewClient(
  TENANT_PATH: string,
  routeUid: string,
  clientDetailsToSave: any
) {
  try {
    const washingtonRef = doc(DB, TENANT_PATH, routeUid);

    await updateDoc(washingtonRef, {
      clientDetails: arrayUnion({
        clientFirebaseUid: clientDetailsToSave.UID,
        clientName: clientDetailsToSave.clientName,
        completed: false,
        serviceAddress: clientDetailsToSave.serviceAddress,
        contactPhone: clientDetailsToSave.contactPhone,
        drivewaySize: clientDetailsToSave.serviceType,
        isShovelingSelected: false,
        activity: [
          {
            timestamp: Date.now(),
            noteTitle: 'Added To Route',
            noteDescription: 'Client was added to the route',
            notificationsDetails: {
              notifyClient: true,
              clientAcknowledged: false,
              tenantAcknowledge: true,
            },
          },
        ],
      }),
    });
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

async function getLandingByteCrateConfig(tenantId: string | undefined, id: string) {
  const TENANT_MESSAGE_PATH = `tenants/${tenantId}/configs`;
  var defaultLandingByteCrateValues: landingByteCrateTypes = {
    activeLandingView: {
      snowAlwaysLandsOnTopView: false,
      defaultView: true,
    },
    snowAlwaysLandsOnTop: {
      landingPageHook: 'No Active Snow Update',
      landingPageMessage:
        'As of now, there are no new developments regarding the recent snow event. We will continue to monitor the situation, ensuring that all necessary measures are in place. We appreciate your patience, and we will promptly update you with any changes. ',
      landingPageMessageUpdated: Timestamp.now(),
      snowEmergencyDeclared: false,
      snowEventDate: Timestamp.now(),
      cardSnowEvent: {
        active: false,
        url: null,
      },
      cardPriorityPush: {
        active: false,
        url: null,
      },
    },
    activeSnowEventId: null,
  };

  const docRef = doc(DB, TENANT_MESSAGE_PATH, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    dispatch(landingByteCrateFetchedSuccesfully());
    const firebaseValues = docSnap.data();
    defaultLandingByteCrateValues = {
      activeLandingView: {
        defaultView: firebaseValues.activeLandingView.defaultView,
        snowAlwaysLandsOnTopView: firebaseValues.activeLandingView.snowAlwaysLandsOnTopView,
      },
      snowAlwaysLandsOnTop: {
        landingPageHook: firebaseValues.snowAlwaysLandsOnTop.landingPageHook,
        landingPageMessage: firebaseValues.snowAlwaysLandsOnTop.landingPageMessage,
        landingPageMessageUpdated: firebaseValues.snowAlwaysLandsOnTop.landingPageMessageUpdated,
        snowEmergencyDeclared: firebaseValues.snowAlwaysLandsOnTop.snowEmergencyDeclared,
        snowEventDate: firebaseValues.snowAlwaysLandsOnTop.snowEventDate,
        cardPriorityPush: {
          active:
            firebaseValues.snowAlwaysLandsOnTop.cardPriorityPush != null
              ? firebaseValues.snowAlwaysLandsOnTop.cardPriorityPush.active
              : false,
          url:
            firebaseValues.snowAlwaysLandsOnTop.cardPriorityPush != null
              ? firebaseValues.snowAlwaysLandsOnTop.cardPriorityPush.url
              : null,
        },
        cardSnowEvent: {
          active:
            firebaseValues.snowAlwaysLandsOnTop.cardSnowEvent.active != null
              ? firebaseValues.snowAlwaysLandsOnTop.cardSnowEvent.active
              : false,
          url:
            firebaseValues.snowAlwaysLandsOnTop.cardSnowEvent.active != null
              ? firebaseValues.snowAlwaysLandsOnTop.cardSnowEvent.url
              : null,
        },
      },
      activeSnowEventId: null,
    };
  } else {
    console.log(
      'No such document for LandingByteCrateValues from Firebase. Returning the default values'
    );
  }
  return defaultLandingByteCrateValues;
}

async function updateLandingByteCrateConfigs(
  tenantId: string,
  landingCrate: landingByteCrateTypes
) {
  ///tenants/C55rHuHjAZ4vX2Og7uTg/configs/landingByteCrate
  const LANDING_BYTE_CRATE_CONFIG_PATH = 'landingByteCrate';
  const TENANT_ROUTES_PATH = `tenants/${tenantId}/configs`;
  // Add a new document with a generated id.

  const routeDocumentRef = doc(DB, TENANT_ROUTES_PATH, LANDING_BYTE_CRATE_CONFIG_PATH);

  await updateDoc(routeDocumentRef, {
    activeLandingView: {
      snowAlwaysLandsOnTopView: landingCrate.activeLandingView.snowAlwaysLandsOnTopView,
      defaultView: landingCrate.activeLandingView.defaultView,
    },
    snowAlwaysLandsOnTop: {
      landingPageHook: landingCrate.snowAlwaysLandsOnTop.landingPageHook,
      landingPageMessage: landingCrate.snowAlwaysLandsOnTop.landingPageMessage,
      landingPageMessageUpdated: landingCrate.snowAlwaysLandsOnTop.landingPageMessageUpdated,
      snowEmergencyDeclared: landingCrate.snowAlwaysLandsOnTop.snowEmergencyDeclared,
      snowEventDate: landingCrate.snowAlwaysLandsOnTop.snowEventDate,
      cardPriorityPush: {
        active: false,
        url: null,
      },
      cardSnowEvent: {
        active: false,
        url: null,
      },
      activeSnowEventId: null,
    },
  }).catch(async (e) => {
    if (e.code === 'not-found') {
      await setDoc(doc(DB, TENANT_ROUTES_PATH, LANDING_BYTE_CRATE_CONFIG_PATH), {
        activeLandingView: {
          snowAlwaysLandsOnTopView: landingCrate.activeLandingView.snowAlwaysLandsOnTopView,
          defaultView: landingCrate.activeLandingView.defaultView,
        },
        snowAlwaysLandsOnTop: {
          landingPageHook: landingCrate.snowAlwaysLandsOnTop.landingPageHook,
          landingPageMessage: landingCrate.snowAlwaysLandsOnTop.landingPageMessage,
          landingPageMessageUpdated: landingCrate.snowAlwaysLandsOnTop.landingPageMessageUpdated,
          snowEmergencyDeclared: landingCrate.snowAlwaysLandsOnTop.snowEmergencyDeclared,
          snowEventDate: landingCrate.snowAlwaysLandsOnTop.snowEventDate,
          cardPriorityPush: {
            active: false,
            url: null,
          },
          cardSnowEvent: {
            active: false,
            url: null,
          },
        },
        activeSnowEventId: null,
      });
    }
  });
}

async function createNewSnowEvent(tenantId: string, snowEventDetails: newSnowEventTypes) {
  const TENANT_SNOW_EVENTS_PATH = `tenants/${tenantId}/snowEvents`;

  // Add a new document with a generated id.
  const docRef = await addDoc(collection(DB, TENANT_SNOW_EVENTS_PATH), {
    activatedRoutes: snowEventDetails.activatedRoutes,
    eventDetails: {
      name: snowEventDetails.eventDetails.name,
      projectedAccumulationInInches: snowEventDetails.eventDetails.projectedAccumulationInInches,
      actualAccumuilationInInches: snowEventDetails.eventDetails.actualAccumuilationInInches,
      scheduledStartTime: snowEventDetails.eventDetails.scheduledStartTime,
      actualEndTime: snowEventDetails.eventDetails.actualEndTime,
    },
    status: snowEventDetails.status,
  });
  return docRef.id;
}

async function updateActivatedRouteDetailsForSnowEvent(
  tenantId: string,
  snowEventId: string,
  updatedRoutesToSave: SnowEventDetailsProps[]
) {
  const TENANT_SNOW_EVENT_PATH = `tenants/${tenantId}/snowEvents/`;
  const routeDocumentRef = doc(DB, TENANT_SNOW_EVENT_PATH, snowEventId);
  await updateDoc(routeDocumentRef, {
    activatedRoutes: updatedRoutesToSave,
  });
}

async function updateStatusForSnowEvent(
  tenantId: string,
  snowEventId: string,
  details: SnowEventPageTypes
) {
  const TENANT_SNOW_EVENT_PATH = `tenants/${tenantId}/snowEvents/`;
  const routeDocumentRef = doc(DB, TENANT_SNOW_EVENT_PATH, snowEventId);
  await updateDoc(routeDocumentRef, {
    status: details.status,
  });
}

async function getTenantSnowEvents(tenantId: string | undefined) {
  const TENANT_SNOW_EVENTS_PATH = `tenants/${tenantId}/snowEvents/`;
  var defaultLandingByteCrateValues: any[] = [];
  const q = query(collection(DB, TENANT_SNOW_EVENTS_PATH));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const dataToPush = {
      ...doc.data(),
      snowEventFirebaseUid: doc.id,
    };
    defaultLandingByteCrateValues.push(dataToPush);
  });

  return defaultLandingByteCrateValues;
}

async function addNewEstimateForClient(
  tenantId: string,
  clientId: string,
  details: {
    estiamteId: string;
    status: string;
    name: string;
  }
) {
  const TENANT_CLIENT_PATH = `tenants/${tenantId}/clients/${clientId}/estimates`;
  const userRef = doc(collection(DB, TENANT_CLIENT_PATH));
  await setDoc(userRef, {
    tenantEstimateUuidCollectionRefrence: details.estiamteId,
    status: details.status,
    name: details.name,
  });
  return userRef.id;
}

async function updateClientEstiamteStatus(
  tenantId: string,
  clientId: string,
  estiamteId: string,
  status: string
) {
  const TENANT_CLIENT_PATH = `tenants/${tenantId}/clients/${clientId}/estimates`;

  const q = query(
    collection(DB, TENANT_CLIENT_PATH),
    where('tenantEstimateUuidCollectionRefrence', '==', estiamteId)
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(async (docs) => {
    // doc.data() is never undefined for query doc snapshots
    // This got complicated to implement for users that are sending more than one address and more than one service is being accpeted. This
    // This also to determine and code for all possible cominations.
    // The current work aroind is for clients that make AMY changes, we should review it.
    // Then once we review it, we can update the flag to its relevant case:
    // CHANGE Status from a list on the Tenant UI
    const FEATURE_IS_TO_COMPLEX_TO_SOLVE_THIS_IS_A_CURRENT_WORK_AROUND_TO_UNBLOCK_US = 'REVIEW';

    const estimateTenantDetails = docs.data();
    console.log('updating the values for ', docs.id, ' =>', estimateTenantDetails.status);
    // Only make the call if its already not reviewed
    if (
      FEATURE_IS_TO_COMPLEX_TO_SOLVE_THIS_IS_A_CURRENT_WORK_AROUND_TO_UNBLOCK_US !=
      estimateTenantDetails.status
    ) {
      console.log('making the call');
      const clientEstimateDetailsToUpdate = doc(DB, TENANT_CLIENT_PATH, docs.id);
      await updateDoc(clientEstimateDetailsToUpdate, {
        status: FEATURE_IS_TO_COMPLEX_TO_SOLVE_THIS_IS_A_CURRENT_WORK_AROUND_TO_UNBLOCK_US,
      });
    }

    // Ifs the incoming value is accpeted, check to see if its matches to what the current status is:
  });
}

export {
  AuthContext,
  AuthProvider,
  testing,
  getTenantClientMessages,
  setCompletedService,
  updateClientCompletedFlag,
  postNewClient,
  postNewEstimate,
  getTenantTodoScheduleForToday,
  getTenantClientList,
  getTenantsClientProfileServiceAddressesDetails,
  getTenantsClientInvoiceDetails,
  getTenantEstimateList,
  getClientDetails,
  postNewClientForEstaimte,
  getClientProfile,
  getTenantStripeId,
  updateTenantStipeAccountInDataBase,
  getEstimateDetails,
  updateEstimatePrice,
  updateEstimateServiceDate,
  addNewEstimateComment,
  updateEstimateLineItemStatus,
  updateEstimateStatus,
  updateClientProfileServiceAddress,
  getTenantPendingSchedulingList,
  handleUploadToTenantClientBucket,
  addNewNoteDetailsToClientEstimate,
  getTenantCrew,
  updareNoteDetailsToClientEstimate,
  checkTenantPromo,
  checkSFMananaDocument,
  // Winter View
  getTenantSaveForMananaDocuments,
  getTenantSnowSnapshot,
  handleUpdatingClientDetailsWithRouteDetails,
  createNewRouteForTenant,
  getTenantRoutes,
  ackClientFromSnapshotSnowFolder,
  updateRouteWithNewClient,
  udpateRouteClientDetailsWholeArray,
  updateActivatedRouteDetailsForSnowEvent,
  getTenantSnowEvents,
  updateStatusForSnowEvent,
  createNewSnowEvent,
  // TenantConfigs
  getLandingByteCrateConfig,
  updateLandingByteCrateConfigs,
  //estimates:
  addNewEstimateForClient,
};
