import { createSlice } from '@reduxjs/toolkit';

import { dispatch } from '../store';
import {
  DotComState,
  landingPageEnum,
  drivewaySizesEnum,
  onboardingSnowClientDetailsTypes,
  drivewaySizesPricesEnum,
  drivewayDetailsTypes,
  pricingStategyEums,
  summrayTypes,
  checkTenantPromoResponse,
  landingByteCrateTypes,
} from 'src/@types/dotCom';
import { Timestamp } from 'firebase/firestore';

const initialState: DotComState = {
  isLoading: false,
  tenantId: null,
  clientId: null,
  sfMananaSessionId: null,
  landingPage: landingPageEnum.LANDING,
  indicators: {
    isLocked: false,
    savedSession: false,
    easterEggIndex: 0,
    easterEgg: 0,
  },
  onboardingSnowClientDetails: {
    fName: null,
    lName: null,
    phone: null,
    email: null,
    address: null,
    city: null,
    state: null,
    zipcode: null,
  },
  drivewayDetailsArray: [
    {
      address: '',
      city: '',
      state: '',
      zipcode: '',
      size: drivewaySizesEnum.TWO_CAR,
      seasonPrice: drivewaySizesPricesEnum.TWO_CAR,
      wbl: {
        price: 0,
        selected: false,
      },
      notes: '',
      discounts: {
        referral: false,
        neighbor: false,
        earlyBird: false,
      },
      overrides: {
        customDrivewayPrice: {
          value: 1250,
          isActive: false,
        },
        customShovelingPrice: {
          isActive: false,
          value: 0,
        },
      },
    },
  ],
  dialogs: {
    newDriveway: false,
    estimateSentToEmail: false,
  },
  summary: {
    pricingStategy: pricingStategyEums.ONE_MONTH,
    balanceDue: drivewaySizesPricesEnum.TWO_CAR / pricingStategyEums.ONE_MONTH,
  },
  snowPromoDialog: {
    isActive: true,
    dialog: {
      dialogImage: '',
    },
    details: {
      amount: 0,
    },
    vendorDetails: {
      name: 'Metta Coffee',
      address: '800 Main St. Anoka, MN 55303',
      logoUrl: 'string',
    },
  },
  snowPromoDialogIsOpen: false,

  buttonStates: {
    showEmailDetails: true,
  },
  landingByteCrate: {
    activeLandingView: {
      snowAlwaysLandsOnTopView: false,
      defaultView: true,
    },
    snowAlwaysLandsOnTop: {
      landingPageHook: 'DEFAULT',
      landingPageMessage: 'DEFAULT',
      landingPageMessageUpdated: Timestamp.now(),
      snowEmergencyDeclared: false,
      snowEventDate: Timestamp.now(),
      cardPriorityPush: {
        active: false,
        url: null,
      },
      cardSnowEvent: {
        active: false,
        url: null,
      },
    },
    activeSnowEventId: null,
  },
};

const slice = createSlice({
  name: 'DotCom',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    setTenantConfigDetailsFromParams(state, action) {
      state.tenantId = action.payload;
    },
    updateTenantPromoDeatils(state, action) {
      state.snowPromoDialog.isActive = action.payload.checkTenantPromoResponse.isActive;
      state.snowPromoDialog.dialog.dialogImage =
        action.payload.checkTenantPromoResponse.dialog.dialogImage;

      state.snowPromoDialog.details.amount = action.payload.checkTenantPromoResponse.details.amount;

      state.snowPromoDialogIsOpen = action.payload.openDialogValue;
    },

    closePromoDeatilsDialog(state) {
      state.snowPromoDialogIsOpen = false;
    },
    setDetailsFromParams(state, action) {
      state.tenantId = action.payload.tenantId;
      state.clientId = action.payload.clientId;
    },
    setnewClientDetailsToRedux(state, action) {
      state.onboardingSnowClientDetails.fName = action.payload.fName;
      state.onboardingSnowClientDetails.lName = action.payload.lName;
      state.onboardingSnowClientDetails.email = action.payload.email;
      state.onboardingSnowClientDetails.phone = action.payload.phone;
      state.onboardingSnowClientDetails.address = action.payload.address;
      state.onboardingSnowClientDetails.city = action.payload.city;
      state.onboardingSnowClientDetails.zipcode = action.payload.zipcode;
      state.onboardingSnowClientDetails.state = action.payload.state;
    },

    updateDrivewayArrayWithNewServiceAddress(state, action) {
      const updatedArray = [...state.drivewayDetailsArray];
      updatedArray[0].city = action.payload.city;
      updatedArray[0].notes = action.payload.notes;
      updatedArray[0].address = action.payload.address;
      updatedArray[0].city = action.payload.city;
      updatedArray[0].state = 'MN';
      updatedArray[0].zipcode = action.payload.zipcode;
      updatedArray[0].notes = action.payload.notes;
      updatedArray[0].discounts = {
        referral: false,
        neighbor: false,
        earlyBird: false,
      };
      state.drivewayDetailsArray = updatedArray;
    },
    updatePageToSummary(state) {
      state.landingPage = landingPageEnum.SUMMARY;
    },
    updatePageToAddressDetails(state) {
      state.landingPage = landingPageEnum.DETAILS;
    },
    setFirstDrivewaySizeDetailsType(state, size) {
      var drivewayValue = size.payload;

      if (drivewayValue == drivewaySizesEnum.TWO_CAR) {
        console.log('TWO_CAR:');
        state.drivewayDetailsArray[0].size = size.payload;
        state.drivewayDetailsArray[0].seasonPrice = drivewaySizesPricesEnum.TWO_CAR;
        state.summary.balanceDue = drivewaySizesPricesEnum.TWO_CAR / state.summary.pricingStategy;
      }
      if (drivewayValue == drivewaySizesEnum.ONE_CAR) {
        console.log('ONE_CAR:');
        state.drivewayDetailsArray[0].size = size.payload;
        state.drivewayDetailsArray[0].seasonPrice = drivewaySizesPricesEnum.ONE_CAR;
        state.summary.balanceDue = drivewaySizesPricesEnum.ONE_CAR / state.summary.pricingStategy;
      }
      if (drivewayValue == drivewaySizesEnum.THREE_CAR) {
        state.drivewayDetailsArray[0].size = size.payload;
        state.drivewayDetailsArray[0].seasonPrice = drivewaySizesPricesEnum.THREE_CAR;
      }
    },
    replaceDrivewaySizeDetails(state, size) {
      var newDrivewayDetailsArray = size.payload;
      var newPrice: number = 0;

      newDrivewayDetailsArray.map((newDrivewayDetails: drivewayDetailsTypes) => {
        const wblPrice = newDrivewayDetails.wbl.selected ? newDrivewayDetails.wbl.price : 0;
        newPrice = newDrivewayDetails.seasonPrice.valueOf() + wblPrice + newPrice;
      });

      // We should update the pricing details here too
      state.drivewayDetailsArray = newDrivewayDetailsArray;
    },
    setNewDrivewayDialogOpenState(state, size) {
      state.dialogs.newDriveway = size.payload;
    },
    updatePricingStategy(state, payload) {
      var newPrice: number = 0;
      state.drivewayDetailsArray.map((newDrivewayDetails: drivewayDetailsTypes) => {
        const wblPrice = newDrivewayDetails.wbl.selected ? newDrivewayDetails.wbl.price : 0;
        newPrice = newDrivewayDetails.seasonPrice + wblPrice + newPrice;
      });

      console.log('inside the Reduc stata');
      state.summary.pricingStategy = payload.payload;
      state.summary.balanceDue = newPrice / payload.payload;
    },
    updateSummaryObject(state, payload) {
      state.summary = payload.payload;
    },
    setEstimateSentToEmailDialogOpenState(state) {
      state.dialogs.estimateSentToEmail = true;
    },
    setEstimateSentToEmailDialogCloseState(state) {
      state.dialogs.estimateSentToEmail = false;
    },
    setEmailButtonStateToFalse(state) {
      state.buttonStates.showEmailDetails = false;
    },
    setDotComState(state, payload) {
      state.drivewayDetailsArray = payload.payload.drivewayDetailsArray;
      state.onboardingSnowClientDetails = payload.payload.onboardingSnowClientDetails;
      state.summary = payload.payload.summary;

      state.landingPage = payload.payload.landingPage;
      state.buttonStates = payload.payload.buttonStates;
      state.sfMananaSessionId = payload.payload.sfMananaSessionId;
      state.indicators.isLocked = payload.payload.indicators.isLocked;
    },
    setSessionSavedIndicator(state) {
      state.indicators.savedSession = true;
    },
    incrementEasterEgg(state) {
      state.indicators.easterEgg = state.indicators.easterEgg + 1;
    },
    resetEasterEgg(state) {
      state.indicators.easterEgg = 0;
    },

    incrementEasterEggIndex(state) {
      state.indicators.easterEggIndex = state.indicators.easterEggIndex + 1;
    },
    resetEasterEggIndex(state) {
      state.indicators.easterEggIndex = 0;
    },

    setLandingByteCrateConfig(state, payload) {
      state.landingByteCrate = payload.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  endLoading,
  setDetailsFromParams,
  setnewClientDetailsToRedux,
  updatePageToSummary,
  updatePageToAddressDetails,
  updateDrivewayArrayWithNewServiceAddress,
  setFirstDrivewaySizeDetailsType,
  setTenantConfigDetailsFromParams,
  updateTenantPromoDeatils,
  replaceDrivewaySizeDetails,
  setNewDrivewayDialogOpenState,
  updatePricingStategy,
  updateSummaryObject,
  closePromoDeatilsDialog,
  setEstimateSentToEmailDialogOpenState,
  setEstimateSentToEmailDialogCloseState,
  setEmailButtonStateToFalse,
  setDotComState,
  setSessionSavedIndicator,
  incrementEasterEgg,
  resetEasterEgg,
  incrementEasterEggIndex,
  resetEasterEggIndex,
  setLandingByteCrateConfig,
} = slice.actions;

export function setLandingByteCrateConfigToReduxState(payload: landingByteCrateTypes) {
  return async () => {
    dispatch(setLandingByteCrateConfig(payload));
  };
}

export function handleIncrementEasterEggIndex() {
  return async () => {
    dispatch(incrementEasterEggIndex());
  };
}

export function handleRestEasterEggIndex() {
  return async () => {
    dispatch(resetEasterEggIndex());
  };
}
export function handleEasterEggClicked() {
  return async () => {
    dispatch(incrementEasterEgg());
  };
}

export function handleEasterEggRest() {
  return async () => {
    dispatch(resetEasterEgg());
  };
}

export function setSessionSavedReduxIndicator() {
  return async () => {
    dispatch(setSessionSavedIndicator());
  };
}

export function updateDotComState(payload: DotComState) {
  return async () => {
    dispatch(setDotComState(payload));
  };
}

export function closePromoDialog() {
  return async () => {
    dispatch(closePromoDeatilsDialog());
  };
}

export function openNewDrivewayDialog() {
  return async () => {
    dispatch(setNewDrivewayDialogOpenState(true));
  };
}

export function closeNewDrivewayDialog() {
  return async () => {
    dispatch(setNewDrivewayDialogOpenState(false));
  };
}

export function openEstimateSentToEmailDialog() {
  return async () => {
    dispatch(setEstimateSentToEmailDialogOpenState());
  };
}

export function disableEmailButtonBcItWasClicked() {
  return async () => {
    dispatch(setEmailButtonStateToFalse());
  };
}

export function closeEstimateSentToEmailDialog() {
  return async () => {
    dispatch(setEstimateSentToEmailDialogCloseState());
  };
}

export function udpateDrivewayDetailsArrayWithNewUpdatedArray(array: drivewayDetailsTypes[]) {
  return async () => {
    dispatch(replaceDrivewaySizeDetails(array));
  };
}

export function setClientInitialDetails(payload: {
  tenantId: string;
  clientId: string;
  token: string | undefined | null;
}) {
  return async () => {
    dispatch(setDetailsFromParams(payload));
  };
}

export function setTenantPromoDetails(
  checkTenantPromoResponse: checkTenantPromoResponse,
  openDialogValue: boolean
) {
  const payload = {
    checkTenantPromoResponse: checkTenantPromoResponse,
    openDialogValue: openDialogValue,
  };
  return async () => {
    dispatch(updateTenantPromoDeatils(payload));
  };
}

export function setTenantSnowBlowingInitialDetails(tenantId: string | undefined) {
  return async () => {
    dispatch(setTenantConfigDetailsFromParams(tenantId));
  };
}

export function updateFirstDrivewayDetailsInArray(drivewayDetails: drivewayDetailsTypes) {
  return async () => {
    dispatch(updateDrivewayArrayWithNewServiceAddress(drivewayDetails));
  };
}
export function updateSummaruReducState(size: summrayTypes) {
  return async () => {
    dispatch(updateSummaryObject(size));
  };
}

export function setFirstDrivewaySize(size: drivewaySizesEnum) {
  return async () => {
    dispatch(setFirstDrivewaySizeDetailsType(size));
  };
}

// Setting the page view of the snow to the Summary View
export function setPageViewToSUMMARY() {
  return async () => {
    dispatch(updatePageToSummary());
  };
}

// Setting the page view of the snow to the More Details View
export function setPageViewToAddressDetails() {
  return async () => {
    dispatch(updatePageToAddressDetails());
  };
}

export function setClientDetailsFromForm(payload: onboardingSnowClientDetailsTypes) {
  return async () => {
    dispatch(setnewClientDetailsToRedux(payload));
  };
}

export function setClientViewLoadingTrue() {
  return async () => {
    dispatch(startLoading());
  };
}

export function setClientViewLoadingFalse() {
  return async () => {
    dispatch(endLoading());
  };
}

export function setDotComLoadingTrue() {
  return async () => {
    dispatch(startLoading());
  };
}

export function setDotComLoadingFalse() {
  return async () => {
    dispatch(endLoading());
  };
}
