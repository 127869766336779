// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },

      {
        title: 'Winter',
        path: PATH_DASHBOARD.general.winter,
        icon: ICONS.blog,
        children: [
          { title: 'Season Details', path: PATH_DASHBOARD.general.winter },
          { title: 'Snow Event Center', path: PATH_DASHBOARD.general.snowEvent },
        ],
      },
      {
        title: 'clients',
        path: PATH_DASHBOARD.clients.root,
        icon: ICONS.user,
        children: [
          { title: 'new', path: PATH_DASHBOARD.clients.new },
          { title: 'list', path: PATH_DASHBOARD.clients.list },
          { title: 'view', path: PATH_DASHBOARD.clients.view }, //TODO: B4MVP: Check to see if esimtate details are loaded. If they are not then navigate to the list. Also create the list view.g
        ],
      },
      { title: 'services', path: PATH_DASHBOARD.clients.newService, icon: ICONS.dashboard },
      // INVOICE
      // {
      //   title: 'invoices',
      //   path: PATH_DASHBOARD.invoice.root,
      //   icon: ICONS.invoice,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.invoice.list },
      //     { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
      //     // { title: 'create', path: PATH_DASHBOARD.invoice.new },
      //     // { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
      //   ],
      // },
      {
        title: 'estimates',
        path: PATH_DASHBOARD.estimate.root,
        icon: ICONS.booking,
        children: [
          { title: 'new', path: PATH_DASHBOARD.estimate.new },
          { title: 'list', path: PATH_DASHBOARD.estimate.list },
          { title: 'view', path: PATH_DASHBOARD.estimate.view }, //TODO: B4MVP: Check to see if esimtate details are loaded. If they are not then navigate to the list. Also create the list view.g
        ],
      },
    ],
  },
];

export default navConfig;
