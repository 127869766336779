import Router from './routes';
import ThemeProvider from './theme';
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ProgressBarStyle />
                <ChartStyle />
                {/* <Settings /> */}
                <ScrollToTop />
                <Router />
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}

/**
 * TODO:
 *
 * - Bug: Estimate list does not filter. take oit out
 * - Time does not work, showing UTC6 instead of local time.
 
 *
 * - Have the serivce look for the request type and then return the respective answer
 */

//DASHBOARD VIEW

// If we're sending an email and there is no price or date, we should ask first if we want to send it? (NON MVP)

// If we haven't sent the email keep the wording, if we did send it change it to estimate sent to Josepablotpt@gmail.com

//ClIENT VIEW
// if the client declines all of them, we should  ask if they are declining, (NON MVP)

// We should make a dialog letting them know thank you, and that they can come to this page if they have any questions after every accept.
// any future updates/communications will be sent to the email address.

// What happens when the client accepts everything??? We can think about that once we address the little things above!

// If the estimate line item is accepted and client declines, and its the only line item, we should update it
// anywhere we use `tenants/${tenantId}/` we should set this in the app Contenx as the Tenant Prefix

/**
 *
 *
 * Bugs found during stage testing:
 *
 * - There is a bug when the client accepts the Estimate it doesnt uopdate correctly the stepper. it updates the status instead of the action :(
 * - When its accepted it feels akwards
 */
