import { createSlice } from '@reduxjs/toolkit';

import { dispatch } from '../store';

import { ByteCrateState } from 'src/@types/byteCrate';

const initialState: ByteCrateState = {
  landingByteCrateLoaded: false,
  tenantCrewMembersLoaded: false,
  snowEventLoadedByParam: false,
};

const slice = createSlice({
  name: 'ByteCrate',
  initialState,
  reducers: {
    setSnowEventLoadedState(state) {
      state.snowEventLoadedByParam = true;
    },
    setLandingByteCrateState(state) {
      state.landingByteCrateLoaded = true;
    },
    setTenantCrewMemebersState(state) {
      state.tenantCrewMembersLoaded = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setLandingByteCrateState, setTenantCrewMemebersState, setSnowEventLoadedState } =
  slice.actions;

export function snowEventDetailsByParamFetchedSuccesfully() {
  return async () => {
    dispatch(setSnowEventLoadedState());
  };
}

export function landingByteCrateFetchedSuccesfully() {
  return async () => {
    dispatch(setLandingByteCrateState());
  };
}

export function tenantCrewMembersFetchedSuccesfully() {
  return async () => {
    dispatch(setTenantCrewMemebersState());
  };
}
