import { setTenantIdToReduxState } from 'src/redux/slices/dashboard';

export default function SetTenantConfigs(user: any, dispatch: (arg0: () => Promise<void>) => void) {
  var tenantIdValue;
  if (user) {
    tenantIdValue = user.tenantId;
  } else {
    tenantIdValue = 'null';
  }
  dispatch(setTenantIdToReduxState(tenantIdValue));
  return true;
}
